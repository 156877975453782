<template>
  <div>
    <!-- 图片资源库 -->
    <el-tabs v-model="activeName" type="card" tab-position="top" @tab-click="handleClick">
      <el-tab-pane v-for="item in panes" :key="item.id" :label="item.value" :name="item.id">
        <el-row :gutter="10">
          <el-col
            :xl="4"
            :lg="6"
            :md="8"
            :sm="10"
            :xs="24"
            :offset="0"
            v-for="obj in fileList.slice((currentPage-1)*pageSize,currentPage*pageSize)"
            :key="obj.fileId"
          >
            <div class="fileList">
              <el-image
                @click="selectedFiles(obj)"
                style="width: 150px; height: 150px"
                :src="fileUrl+obj.filePath"
                fit="contain"
              ></el-image>
              <label @click="delFiles(obj)" class="delFileImage">
                <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF"></i>
              </label>
              <div class="selectFile" v-if="obj.selected" @click="selectedFiles(obj)">
                <div class="selectIcon">
                  <i class="el-icon-check"></i>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="4" style="margin-top:20px;">
            <el-upload
              class="avatar-uploader"
              :action="actionUrl"
              :headers="headers"
              :data="{type:type,category:category}"
              :show-file-list="false"
              :on-success="handleSuccess"
              :before-upload="beforeUpload"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-col>
        </el-row>

        <el-row style="margin-top:10px">
          <div>
            <el-pagination
              background
              layout="prev, pager, next"
              :hide-on-single-page="false"
              :total="fileList.length"
              :page-size="pageSize"
              :current-page="currentPage"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { getFileApi, delFileApi } from "../api/data";
import Aes from "../utils/aes";
export default {
  data() {
    return {
      //上传头部POST请求头部带token信息验证登录状态
      headers: {
        accessToken: this.$store.state.user.authorization
      },
      currentPage: 1, //当前页
      pageSize: 11, //一页显示多少条
      activeName: "全部",
      panes: [
        { id: "全部", value: "全部" },
        { id: "图片", value: "图片" },
        { id: "图标", value: "图标" }
      ],
      //查询条件
      form: {
        type: 0,
        category: "全部"
      },
      //所有资源集合
      fileList: [],
      //选中资源集合
      selectedFileArray: []
    };
  },
  props: {
    choice: Boolean, //是否允许多选:: true=>单选 false=>多选
    type: Number, //图片：0
    category: String, //分类
    count: Number //多选条数
  },
  computed: {
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    }, //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    }
  },
  mounted() {
    this.initLoad();
  },
  methods: {
    //初始化图片
    initLoad() {
      getFileApi(this.form).then(res => {
        console.log(res);
        this.fileList = res.data.map(item => {
          return { ...item, selected: false };
        });
      });
    },
    //切换分类
    handleClick() {
      this.form.category = this.activeName;
      this.currentPage = 1;
      this.initLoad();
    },
    //点击第几页
    handleCurrentChange: function(currentPage) {
      this.currentPage = currentPage;
    },
    //删除事件
    delFiles(obj) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delFileApi({ id: obj.fileId }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      });
    },
    //选中文件事件
    selectedFiles(obj) {
      if (this.choice) {
        if (!obj.selected) {
          for (let i = 0; i < this.fileList.length; i++) {
            this.fileList[i].selected = false;
          }
          this.selectedFileArray = [];
          this.selectedFileArray.push(obj);
        } else {
          for (let i = 0; i < this.selectedFileArray.length; i++) {
            if (obj.fileId == this.selectedFileArray[i].fileId) {
              this.selectedFileArray.splice(i, 1);
            }
          }
        }
        obj.selected = !obj.selected;
        this.$store.commit("setFileList", this.selectedFileArray);
      } else {
        if (this.selectedFileArray.length == this.count) {
          this.$message.warning("最多只能添加9张图片");
          return false;
        }
        if (!obj.selected) {
          this.selectedFileArray.push(obj);
        } else {
          for (let i = 0; i < this.selectedFileArray.length; i++) {
            if (obj.fileId == this.selectedFileArray[i].fileId) {
              this.selectedFileArray.splice(i, 1);
            }
          }
        }
        obj.selected = !obj.selected;
        this.$store.commit("setFileList", this.selectedFileArray);
      }
    },
    //上传完成事件
    handleSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.$message.success("上传成功");
        this.initLoad();
      } else {
        this.$message.error(result.msg);
      }
       this.initLoad();
    },
    //上传前事件
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    }
  }
};
</script>
<style lang='scss'>
.fileList {
  position: relative;
  margin-top: 20px;
  cursor: pointer;
  overflow: hidden;
  width: 150px;
  height: 150px;
  background: rgba(235, 235, 235, 0.5);
  border-radius: 6px;
  border: 1px dashed #d9d9d9;
}

.fileList:hover {
  border-color: #5e72f9;
}

.delFileImage {
  width: 50px;
  height: 30px;
  background: #f56c6c;
  transform: rotate(45deg);
  position: absolute;
  right: -17px;
  top: -7px;
  text-align: center;
  padding-top: 11px;
  box-shadow: 0 1px 1px #ccc;
  cursor: pointer;
}
.selectFile {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 150px;
  height: 150px;
  .selectIcon {
    position: absolute;
    top: 38%;
    width: 100%;
    text-align: center;
    font-size: 40px;
    color: #67c23a;
  }
}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  width: 150px;
}
.avatar-uploader:hover {
  border-color: #5e72f9;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
</style>
