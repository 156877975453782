<template>
  <div class="renewals">
    <!-- 充值续费 -->
    <div style="padding:10px 0">
      <div>
        <span class="recharge_places_title">终端数量&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <el-input-number
          v-model="rechargeClient"
          :min="minClientNumber"
          :max="500"
          size="small"
          v-if="isAddClient"
        ></el-input-number>
        <span v-else>{{rechargeClient}}</span>
      </div>

      <span class="recharge_places_title">套餐选择</span>
      <div class="recharge_places_view">
        <div
          v-for="(item,index) in rechargeList.slice((rechargePage-1)*rechargePageSize,rechargePage*rechargePageSize)"
          :key="index"
          :class="isSelectedRechargePackageId==item.id?'recharge_places_card_seleted':'recharge_places_card'"
          @click="selectedPackage(item)"
        >
          <div class="recharge_places_discount">{{item.packageMark}}</div>

          <div class="recharge_places_card_top">
            <span>{{item.packageName}}</span>
          </div>
          <div class="recharge_places_card_middle">
            <span class="recharge_places_card_middle_one">￥</span>
            <span class="recharge_places_card_middle_two">{{item.packagePrice}}</span>
            <span class="recharge_places_card_middle_three">/台</span>
          </div>
        </div>
      </div>
      <div class="recharge_places_page">
        <el-pagination
          layout="prev, next"
          :hide-on-single-page="false"
          :total="rechargeList.length"
          :page-size="rechargePageSize"
          :current-page="rechargePage"
          @current-change="handleRechargeCurrentChange"
        ></el-pagination>
      </div>
      <div v-show="isRechargePackageRemark" style="padding:10px 0">
        <div>{{isRechargePackageRemark}}</div>
      </div>
      <div>
        <span class="recharge_places_title">支付方式</span>
        <div>
          <el-tabs
            v-model="activePay"
            tab-position="left"
            type="border-card"
            style="height: 200px;box-shadow:none;"
            @tab-click="openCode"
          >
            <el-tab-pane label="微信" name="wechat">
              <div v-if="activePay=='wechat'" v-loading="loading" class="wxStyle" style>
                <div ref="qrcodeImg" class="payment-amount"></div>
                <div v-show="showMoney">
                  支付金额：
                  <span style="color: #f60;font-size: 40px;font-weight: bold;">{{showMoney}}</span>元
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="支付宝" name="alipay">
              <div
                v-if="activePay=='alipay'"
                v-loading="loading"
                style="height:200px;display:flex;justify-content:space-around;align-items:center"
              >
                <iframe
                  :srcdoc="alipay_url"
                  frameborder="no"
                  border="0"
                  marginwidth="10"
                  marginheight="10"
                  scrolling="yes"
                  style="overflow:hidden"
                ></iframe>
                <div v-show="showMoney">
                  支付金额：
                  <span style="color: #f60;font-size: 40px;font-weight: bold;">{{showMoney}}</span>元
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div>
          <p class="recharge_places_title">注意事项</p>
          <p>1.如若充值失败，请联系**********</p>
          <p>2.提示信息</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getPayPackageByUserIdApi,
  alipayRechargeApi,
  wechatRechargeApi,
  getPayRechargeStatusApi
} from "../api/data";
import QRCode from "qrcodejs2";
import DateFormat from "../utils/date";
export default {
  data() {
    return {
      rechargeList: [], //用户支付的套餐
      rechargePage: 1, //套餐当前页
      rechargePageSize: 5, //套餐一页显示多少条
      isSelectedRechargePackageId: "", //选中的支付套餐对象
      isRechargePackageRemark: "", //选中套餐的说明
      rechargeClient: Number, //充值窗口中的终端数
      minClientNumber: Number, //充值窗口中的最小终端数
      alipay_url: "", //支付宝支付地址
      timer: null,
      activePay: "alipay",
      showMoney: "", //显示充值金额
      loading: false, //二维码加载显示
      isAddClient: true, //该场所是否到期 true到期 false 未到期
      payTimer: null //查询支付完成回调的定时器
    };
  },
  props: {
    groupItem: {}
  },
  watch: {
    //监听修改终端数量
    rechargeClient() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.isSelectedRechargePackageId != "") {
          this.openCode();
        }
      }, 1000);
    }
  },
  //关闭窗体销毁事件
  destroyed() {
    clearTimeout(this.payTimer);
  },
  created() {
    this.isAddClient = DateFormat.judgeTime(this.groupItem.expireTime);
    //试用期内充值直接取当前场所的设备数量
    let clientNumber = (this.groupItem.clientNumber<=0) ? 1 : this.groupItem.clientNumber;
    if (this.groupItem.status == "trial") {
      this.rechargeClient = clientNumber;
      this.minClientNumber = clientNumber;
    } else {
      //正常使用期限内充值
      if (this.isAddClient) {
        //已经过期,直接最小不能低于当前场所设备数量
        this.rechargeClient = clientNumber;
        this.minClientNumber = clientNumber;
      } else {
        //未过期 不考虑是否用户有没有注册满设备直接充值最大设备数
        this.rechargeClient = this.groupItem.maxRegister;
        this.minClientNumber = this.groupItem.maxRegister;
      }
    }
    this.initPayPackage();
  },
  methods: {
    //选择完套餐重新显示二维码
    selectedPackage(item) {
      this.isSelectedRechargePackageId = item.id;
      this.isRechargePackageRemark = item.remark;
      this.openCode();
    },
    //查询用户支付的套餐
    initPayPackage() {
      getPayPackageByUserIdApi().then(res => {
        if (res) {
          this.rechargeList = res.data;
          this.isSelectedRechargePackageId = res.data[0].id;
          this.isRechargePackageRemark = res.data[0].remark;
          //   this.openCode();
        }
      });
    },
    //显示支付二维码
    openCode() {
      clearTimeout(this.payTimer);
      if (this.activePay == "wechat") {
        this.loading = true;
        this.$nextTick(() => {
          wechatRechargeApi({
            packageId: this.isSelectedRechargePackageId,
            groupId: this.groupItem.groupId,
            payType: "charge_group",
            clientNumber: this.rechargeClient
          }).then(res => {
            var result = JSON.parse(res.data);
            //支付总金额
            this.showMoney = result.price;
            //微信支付二维码
            var wx_pay = JSON.parse(res.msg);
            this.qrcode(wx_pay.code_url);
            //定时查询支付状态
            this.payTimer = setInterval(() => {
              this.handleQueryPayStatus(result.orderFormId);
            }, 1000);
          }, 2000);
        });
      } else {
        this.loading = true;
        alipayRechargeApi({
          packageId: this.isSelectedRechargePackageId,
          groupId: this.groupItem.groupId,
          payType: "charge_group",
          clientNumber: this.rechargeClient
        }).then(res => {
          this.$nextTick(() => {
            var result = JSON.parse(res.data);
            //支付总金额
            this.showMoney = result.price;
            //支付宝二维码
            this.alipay_url = res.msg;
            this.loading = false;
            //定时查询支付状态
            this.payTimer = setInterval(() => {
              this.handleQueryPayStatus(result.orderFormId);
            }, 1000);
          }, 2000);
        });
      }
    },
    //查询回调
    handleQueryPayStatus(id) {
      getPayRechargeStatusApi({ orderFormId: id }).then(res => {
        if (res.data.status == "TRADE_SUCCESS") {
          this.$message.success("支付成功");
          clearTimeout(this.payTimer);
          this.$emit("callBackPay", true);
        }
      });
    },
    //微信地址转换为二维码
    qrcode(url) {
      this.loading = false;
      this.$refs.qrcodeImg.innerHTML = "";
      return new QRCode(this.$refs.qrcodeImg, {
        width: 120,
        height: 120,
        text: url,
        colorDark: "#000",
        colorLight: "#fff"
      });
    },
    //套餐点击第几页
    handleRechargeCurrentChange(currentPage) {
      this.rechargePage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
.renewals {
  .recharge_places_title {
    font-size: 16px;
    color: #000000;
    line-height: 30px;
  }

  .recharge_places_view {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .recharge_places_card {
    width: 150px;
    margin: 5px;
    padding: 10px 0px;
    border: 1px solid #d9d9d9;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
  }
  .recharge_places_card_seleted {
    width: 150px;
    margin: 5px;
    padding: 10px 0px;
    border: 1px solid #eabb6a;
    position: relative;
    border-radius: 4px;
    background: #fffcf5;
    cursor: pointer;
  }
  .recharge_places_discount {
    color: #ffffff;
    background: #009dff;
    background-image: linear-gradient(139deg, #ff715c, #fe2b38);
    position: absolute;
    top: -10px;
    left: 0px;
    border-radius: 6.75px 0 6.75px 0;
    padding: 2px 10px;
    font-size: 12px;
    line-height: 20px;
  }
  .recharge_places_card_top {
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 600;
    padding-top: 10px;
    align-items: center;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .recharge_places_card_middle {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    .recharge_places_card_middle_one {
      color: #f60;
      padding-top: 15px;
    }
    .recharge_places_card_middle_two {
      color: #f60;
      font-size: 40px;
      font-weight: bold;
    }
    .recharge_places_card_middle_three {
      color: rgba(255, 102, 0, 0.35);
      margin-left: 4px;
      padding-top: 15px;
      font-size: 14px;
    }
  }
  .recharge_places_page {
    display: flex;
    justify-content: center;
  }
  .el-tabs--border-card > .el-tabs__content {
    padding: 15px 0 0 0;
  }
  .wxStyle {
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .payment-amount {
    margin-top: -26px;
    width: 300px;
  }
}
</style>
