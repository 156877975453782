<template>
  <el-container class="layout">
    <el-header>
      <Head :isShowGroup="false"></Head>
    </el-header>
    <el-main class="group_main">
      <div class="group_list_main">
        <el-row :gutter="0">
          <img src="../assets/placesList/hotel_main.png" height="80" />
        </el-row>
        <div class="group_search">
          <div class="group_search_left">
            <i
              class="el-icon-menu menuIcon"
              :style="isShowPlacesMenu=='card'?'color:#ffffff':''"
              @click="setIsShowPlacesMenu('table')"
              v-if="isShowPlacesMenu=='card'"
            ></i>
            <i
              class="el-icon-s-operation menuIcon"
              :style="isShowPlacesMenu=='table'?'color:#ffffff':''"
              @click="setIsShowPlacesMenu('card')"
              v-else
            ></i>
            <el-button
              type="primary"
              size="small"
              class="group_addBtn"
              icon="el-icon-circle-plus-outline"
              @click="dialogFormVisible= !dialogFormVisible"
            >新增场所</el-button>
          </div>
          <div>
            <el-input
              v-model="search"
              placeholder="请输入场所名或编号"
              suffix-icon="el-icon-search"
              style="width:300px"
              size="small"
              @change="initGroup"
            ></el-input>
          </div>
        </div>

        <el-row :gutter="0" v-show="isShowPlacesMenu=='card'">
          <el-col
            :xl="4"
            :lg="6"
            :md="8"
            :sm="10"
            :xs="24"
            v-for="(item,index) in list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
            :key="index"
            class="box_col"
          >
            <div class="box_card">
              <div class="box_card_body">
                <div class="box_cms_qyb" v-if="item.groupType=='company'">
                  <p>企业版</p>
                </div>
                <div class="box_cms_grb" v-else>
                  <p>个人版</p>
                </div>
                <div class="demo-type" style="margin-top:10px;">
                  <el-avatar :size="60" :src="fileUrl+item.logoUrl" :key="item.logoUrl"></el-avatar>
                </div>
                <div class="box_name">
                  <h2>{{item.groupName}}</h2>
                </div>
                <div style="margin-top:10px;color:#909399">
                  <span>编号:{{item.groupId}}</span>
                </div>
                <div style="margin-top:10px;color:#909399">
                  <i class="el-icon-location-outline" style="font-size:18px"></i>
                  <span>{{item.provinceName}} {{item.cityName}} {{item.regionName}}</span>
                </div>
                <div style="margin-top:20px">
                  <el-button size="mini" plain @click="toHome(item)" class="group_in">进入场所</el-button>
                </div>
                <div class="box_bottom">
                  <el-link :underline="false" @click="dialogRecharge=true">充值</el-link>
                  <el-divider direction="vertical"></el-divider>
                  <el-link :underline="false" @click="onEditFormBtn(item)">编辑</el-link>
                  <el-divider direction="vertical"></el-divider>
                  <el-link :underline="false">删除</el-link>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="0" v-show="isShowPlacesMenu=='table'">
          <div class="tablesGroupTitle">
            <el-col :span="1" :offset="0">版本</el-col>
            <el-col :span="6" :offset="0">场所名称</el-col>
            <el-col :span="1" :offset="0">场所编号</el-col>
            <el-col :span="3" :offset="0">地址</el-col>
            <el-col :span="3" :offset="0">终端数量</el-col>
            <el-col :span="2" :offset="0">有效期</el-col>
            <el-col :span="3" :offset="0">终端支付套餐</el-col>
            <el-col :span="3" :offset="0">操作</el-col>
            <el-col :span="2" :offset="0"></el-col>
          </div>
        </el-row>

        <el-row
          :gutter="0"
          v-show="isShowPlacesMenu=='table'"
          v-for="(item,index) in list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
          :key="index"
        >
          <div class="tablesGroup">
            <el-col :span="1" :offset="0">
              <img
                src="../assets/placesList/table_qyb.png"
                v-if="item.groupType=='company'"
                class="tables_version"
              />
              <img src="../assets/placesList/table_grb.png" v-else class="tables_version" />
            </el-col>
            <el-col
              :span="6"
              :offset="0"
              style="display:flex; align-items:center;text-align:left;padding-left:9%"
            >
              <el-avatar :size="25" :src="fileUrl+item.logoUrl" :key="item.logoUrl"></el-avatar>
              <span style="margin-left:10px;">{{item.groupName}}</span>
            </el-col>
            <el-col :span="1" :offset="0">{{item.groupId}}</el-col>
            <el-col :span="3" :offset="0">{{item.provinceName}}{{item.cityName}}{{item.regionName}}</el-col>
            <el-col :span="3" :offset="0">{{item.clientNumber}}</el-col>
            <el-col :span="2" :offset="0">{{item.groupType=='company'?item.expireTime:'单个终端计费'}}</el-col>
            <el-col :span="3" :offset="0">
              <span>
                {{item.packageName? item.packageName:'未指定套餐' }}
                <el-dropdown placement="bottom" trigger="click" @command="onSubmitPackageGroup">
                  <span class="el-dropdown-link" v-show="packageGroupList.length>0">
                    <i
                      class="el-icon-edit-outline"
                      style="color:#5e72f9;font-size:15px;margin-left:10px;cursor:pointer;vertical-align:middle"
                    ></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="group in packageGroupList"
                      :key="group.id"
                      :command="composeValue(item,group.id)"
                    >{{group.packageName}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </el-col>
            <el-col :span="3" :offset="0">
              <el-link
                :underline="false"
                type="primary"
                @click="openRecharge(item)"
                v-if="item.groupType=='company'"
              >续费</el-link>
              <el-divider direction="vertical" v-if="item.groupType=='company'"></el-divider>
              <el-link :underline="false" type="primary" @click="onEditFormBtn(item)">编辑</el-link>
              <el-divider direction="vertical"></el-divider>
              <el-link type="primary" :underline="false" @click="onDelFormBtn(item)">删除</el-link>

              <el-divider direction="vertical" v-if="item.groupType=='company'"></el-divider>
              <el-link
                type="primary"
                :underline="false"
                v-if="item.groupType=='company' && userInfo.userLevel=='super_admin'"
                @click="openManage(item)"
              >授权</el-link>
            </el-col>
            <el-col :span="2" :offset="0">
              <el-button plain type="primary" size="mini" @click="toHome(item)">进入场所</el-button>
            </el-col>
          </div>
        </el-row>

        <el-row>
          <div>
            <el-pagination
              background
              layout="total,prev, pager, next"
              :hide-on-single-page="false"
              :total="list.length"
              :page-size="pageSize"
              :current-page="currentPage"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </el-row>
      </div>
    </el-main>

    <!-- 新增场所窗口 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogFormVisible"
    >
      <span slot="title">新增场所</span>
      <div v-loading="loading">
        <div class="groupTypeClass">
          <div
            :class="form.groupType=='company'?'selectedModal_qyb':'groupModal'"
            @click="form.groupType='company'"
          >
            <img
              src="../assets/placesList/places_selected.png"
              class="complete"
              v-if="form.groupType=='company'"
            />
            <p class="title">企业版</p>
            <p>到期时间以场所注册时间为主</p>
          </div>
          <div
            :class="form.groupType=='person'?'selectedModal_grb':'groupModal'"
            @click="form.groupType='person'"
          >
            <img
              src="../assets/placesList/places_selected.png"
              class="complete"
              v-if="form.groupType=='person'"
            />
            <p class="title">个人版</p>
            <p>到期时间已设备注册时间为主</p>
          </div>
        </div>
        <el-form
          :model="form"
          ref="form"
          label-position="right"
          label-width="100px"
          size="small"
          style="padding:20px"
          hide-required-asterisk
        >
          <el-form-item
            label="场所名称"
            prop="groupName"
            :rules="[{ required: true, message: '请输入场所名称', trigger: 'blur' },{
            min: 3,
            max: 13,
            message: '长度在 3 到 13 个字符',
            trigger: 'blur'
          }]"
          >
            <el-input v-model="form.groupName" minlength="3" maxlength="13" show-word-limit></el-input>
          </el-form-item>
          <el-form-item
            label="地区"
            prop="optionValue"
            :rules=" { required: true, message: '请选择地区', trigger: 'change' }"
          >
            <el-cascader
              v-model="form.optionValue"
              :options="options"
              @change="handleChange"
              style="width:100%"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            label="详细地址"
            prop="address"
            :rules="{ required: true, message: '请输入详细地址', trigger: 'blur' }"
          >
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item label="联系人">
            <el-input v-model="form.contact"></el-input>
          </el-form-item>
          <el-form-item
            label="联系电话"
            prop="phone"
            :rules="[{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
          >
            <el-input v-model="form.phone" maxlength="11" show-word-limit></el-input>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="备用联系人">
                <el-input v-model="form.contact1"></el-input>
              </el-form-item>
              <el-form-item
                label="备用电话"
                prop="phone1"
                :rules="[{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
              >
                <el-input v-model="form.phone1" maxlength="11" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备用联系人">
                <el-input v-model="form.contact2"></el-input>
              </el-form-item>
              <el-form-item
                label="备用电话"
                prop="phone2"
                :rules="[{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
              >
                <el-input v-model="form.phone2" maxlength="11" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="form.remark" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="LOGO"  prop="logoUrl">
            <el-dropdown placement="top">
              <div>
                <div class="avatar-uploader-logo">
                  <i class="el-icon-plus avatar-uploader-logo-icon" v-if="!form.logoUrl"></i>
                  <el-image
                    v-else
                    style="width: 100px; height: 100px;background:rgba(235,235,235,0.5)"
                    :src="fileUrl+ form.logoUrl"
                    fit="contain"
                  ></el-image>
                </div>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="dialogFileUpload=true"
                  style="padding:0"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                <el-dropdown-item style="padding:0">
                  <el-upload
                    style="text-align:center"
                    :action="actionUrl"
                    accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                    :headers="header"
                    :data="{type:0,category:'图标'}"
                    :show-file-list="false"
                    :on-success="handleAddSuccess"
                    :before-upload="beforeAddUpload"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" size="small" @click="dialogFormVisible=false">取 消</el-button>
        <el-button type="primary" @click="onSubmitAddPlaces" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改场所窗口 -->
    <el-dialog
      :visible.sync="dialogFormEditVisible"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogFormEditVisible"
    >
      <span slot="title">编辑场所</span>
      <div class="groupTypeClass">
        <div
          :class="editForm.groupType=='company'?'selectedModal_qyb':'groupModal'"
          @click="editForm.groupType='company'"
        >
          <img
            src="../assets/placesList/places_selected.png"
            class="complete"
            v-if="editForm.groupType=='company'"
          />
          <p class="title">企业版</p>
          <p>到期时间以场所注册时间为主</p>
        </div>
        <div
          :class="editForm.groupType=='person'?'selectedModal_grb':'groupModal'"
          @click="editForm.groupType='person'"
        >
          <img
            src="../assets/placesList/places_selected.png"
            class="complete"
            v-if="editForm.groupType=='person'"
          />
          <p class="title">个人版</p>
          <p>到期时间已设备注册时间为主</p>
        </div>
      </div>
      <el-form
        :model="editForm"
        ref="editForm"
        label-position="right"
        label-width="100px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="场所名称"
          prop="groupName"
          :rules="[{ required: true, message: '请输入场所名称', trigger: 'blur' },{
            min: 3,
            max: 13,
            message: '长度在 3 到 13 个字符',
            trigger: 'blur'
          }]"
        >
          <el-input v-model="editForm.groupName" minlength="3" maxlength="13" show-word-limit></el-input>
        </el-form-item>
        <el-form-item
          label="地区"
          prop="optionValue"
          :rules=" { required: true, message: '请选择地区', trigger: 'change' }"
        >
          <el-cascader
            v-model="editForm.optionValue"
            :options="options"
            @change="editHandleChange"
            style="width:100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="详细地址"
          prop="address"
          :rules="{ required: true, message: '请输入详细地址', trigger: 'blur' }"
        >
          <el-input v-model="editForm.address"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="editForm.contact"></el-input>
        </el-form-item>
        <el-form-item
          label="联系电话"
          prop="phone"
          :rules="[{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
        >
          <el-input v-model="editForm.phone" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="备用联系人">
              <el-input v-model="editForm.contact1"></el-input>
            </el-form-item>
            <el-form-item
              label="备用电话"
              prop="phone1"
              :rules="[{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
            >
              <el-input v-model="editForm.phone1" maxlength="11" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备用联系人">
              <el-input v-model="editForm.contact2"></el-input>
            </el-form-item>
            <el-form-item
              label="备用电话"
              prop="phone2"
              :rules="[{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
            >
              <el-input v-model="editForm.phone2" maxlength="11" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          <el-input v-model="editForm.remark" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="LOGO">
          <el-dropdown placement="top">
            <div>
              <div class="avatar-uploader-logo">
                <i class="el-icon-plus avatar-uploader-logo-icon" v-if="!editForm.logoUrl"></i>
                <el-image
                  v-else
                  :src="fileUrl+ editForm.logoUrl"
                  style="width: 100px; height: 100px;background:rgba(235,235,235,0.5);"
                  fit="contain"
                ></el-image>
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="dialogEditFileUpload=true"
                style="padding:0"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
              <el-dropdown-item style="padding:0">
                <el-upload
                  style="text-align:center"
                  :action="actionUrl"
                  accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                  :headers="header"
                  :data="{type:0,category:'图标'}"
                  :show-file-list="false"
                  :on-success="handleEditSuccess"
                  :before-upload="beforeEditUpload"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" size="small" @click="dialogFormEditVisible=false">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditPlaces" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 添加图片窗口 -->
    <el-dialog
      :visible.sync="dialogFileUpload"
      :close-on-click-modal="false"
      width="55%"
      append-to-body
      @closed="closeDialogFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图标'" v-if="dialogFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitAddLogo" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改场所--添加图片窗口 -->
    <el-dialog
      :visible.sync="dialogEditFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图标'" v-if="dialogEditFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditLogo" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 续费充值 -->
    <el-dialog :visible.sync="dialogRecharge" :close-on-click-modal="false" width="45%">
      <span slot="title">续费</span>
      <el-tabs v-model="activeRechargeName" stretch>
        <el-tab-pane label="充值续费" name="first">
          <Renewals
            :groupItem="rechargeGroupItem"
            v-on:callBackPay="onSubmitCallBackPay"
            v-if="activeRechargeName=='first' &&dialogRecharge"
          ></Renewals>
        </el-tab-pane>
        <el-tab-pane label="增加授权设备" name="second" v-if="!isAddClient">
          <AddDevice
            :groupItem="rechargeGroupItem"
            v-on:callBackPay="onSubmitCallBackPay"
            v-if="activeRechargeName=='second'&&dialogRecharge"
          ></AddDevice>
        </el-tab-pane>
      </el-tabs>

      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" size="small" @click="dialogRecharge=false">取 消</el-button>
        <el-button type="primary" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 超级管理员授权管理 -->
    <el-dialog :visible.sync="dialogManage" :close-on-click-modal="false" width="25%">
      <span slot="title">授权</span>
      <el-form
        :model="manageForm"
        ref="manageForm"
        label-width="80px"
        size="small"
        style="padding:20px"
      >
        <el-form-item label="过期时间">
          <el-date-picker
            v-model="manageForm.expireTime"
            type="date"
            placeholder="请选择到期日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="授权数量">
          <el-input-number v-model="manageForm.clientCount" :min="1"></el-input-number>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" size="small" @click="dialogManage = false">取 消</el-button>
        <el-button type="primary" size="small"  @click="update(manageForm)" >确 定</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
import {
  getGroupListApi,
  getGroupListByGroupIdApi,
  getRegionListApi,
  addPlacesApi,
  editPlacesApi,
  delPlacesApi,
  editRegistsApi,
  getPackageGroupByIdApi
} from "../api/data";
import FileUpload from "../components/FileUpload";
import Head from "./layout/menu/Head";
import Renewals from "../components/Renewals";
import AddDevice from "../components/AddDevice";
import Aes from "../utils/aes";
import DateFormat from "../utils/date";
export default {
  components: {
    FileUpload,
    Head,
    Renewals,
    AddDevice
  },
  data() {
    return {
      loading: false,
      list: [], //场所数据集合
      currentPage: 1, //当前页
      pageSize: 12, //一页显示多少条
      search: "", //搜索条件
      dialogFormVisible: false, //新增场所窗口
      dialogFormEditVisible: false, //修改场所窗口
      dialogFileUpload: false, //上传图片窗口
      dialogEditFileUpload: false, //修改场所-上传图片窗口
      dialogRecharge: false, //充值续费窗口
      dialogManage: false, //超级管理员授权管理
      form: {
        optionValue: [], //省市区集合
        groupName: "",
        province: "",
        city: "",
        region: "",
        address: "",
        contact: "",
        phone: "",
        contact1: "",
        phone1: "",
        contact2: "",
        phone2: "",
        remark: "",
        groupType: "company",
        logoUrl: ""
      },
      editForm: {
        optionValue: [], //省市区集合
        groupName: "",
        province: "",
        city: "",
        region: "",
        address: "",
        contact: "",
        phone: "",
        contact1: "",
        phone1: "",
        contact2: "",
        phone2: "",
        remark: "",
        groupType: "company",
        logoUrl: ""
      },
      options: [], //地区JSON
      packageGroupList: [], //套餐组集合
      rechargeGroupItem: {}, //续费选择的场所
      activeRechargeName: "first", //续费界面切换
      isAddClient: false, //是否显示添加授权台数窗口
      //授权窗口对象
      manageForm: {
        expireTime: "",
        clientCount: 1,
        groupId: ""
      }
    };
  },

  created() {
    this.initGroup();
    this.initRegion();
    this.initPackageGroup();
  },
  computed: {
    //本地储存用户选择的显示模式
    isShowPlacesMenu() {
      return this.$store.state.tab.isShowPlacesMenu;
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    actionUrl(){
      return this.$store.state.fileUrl+"/file/upload";
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //登录账户信息
    userInfo() {
      if (this.$store.state.user.userInfo != "") {
        return JSON.parse(Aes.decode(this.$store.state.user.userInfo));
      } else {
        return "";
      }
    }
  },
  methods: {
    //初始化场所
    initGroup() {
      getGroupListApi({ groupIdName: this.search }).then(res => {
        if (res) {
          this.list = res.data;
        }
      });
    },
    //初始化地区
    initRegion() {
      getRegionListApi().then(res => {
        if (res) this.options = res.data;
      });
    },
    //查询用户的套餐组
    initPackageGroup() {
      getPackageGroupByIdApi().then(res => {
        if (res) this.packageGroupList = res.data;
      });
    },

    //修改套餐下拉数据处理
    composeValue(obj, id) {
      return { obj: obj, id: id };
    },
    //选择地区回调
    handleChange(value) {
      this.form.province = value[0];
      this.form.city = value[1];
      this.form.region = value[2];
    },
    //修改场所套餐
    onSubmitPackageGroup(command) {
      var obj = { ...command.obj };
      obj.hotelPackageId = command.id;
      editPlacesApi(obj).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initGroup();
        }
      });
    },

    //打开修改场所窗口
    onEditFormBtn(obj) {
      getGroupListByGroupIdApi({ groupId: obj.groupId }).then(res => {
        if (res) {
          this.dialogFormEditVisible = true;
          this.editForm = res.data;
          var optionValue = [obj.province, obj.city, obj.region];
          this.editForm.optionValue = optionValue;
        }
      });
    },
    //修改场所窗口地区回调
    editHandleChange(value) {
      this.editForm.province = value[0];
      this.editForm.city = value[1];
      this.editForm.region = value[2];
    },
    //修改窗口中的上传事件
    //上传完成事件
    handleEditSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.editForm.logoUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
       this.initLoad();
    },
    //上传前事件
    beforeEditUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //修改场所窗口-修改logo
    onSubmitEditLogo() {
      console.log(this.$store.state.fileList[0].filePath);
      this.dialogEditFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.editForm.logoUrl = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //图片窗口关闭事件
    closeDialogFileUpload() {
      this.$store.commit("setFileList", []);
    },
    //点击第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },

    //添加窗口中的上传事件
    //上传完成事件
    handleAddSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.form.logoUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
       this.initLoad();
    },
    //上传前事件
    beforeAddUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },

    //添加LOGO
    onSubmitAddLogo() {
      this.dialogFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.form.logoUrl = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //选择场所后跳转主页
    toHome(obj) {
      //vuex中存储当前选择场所信息
      this.$store.commit("setMenuSelected", ""); //设置为空默认显示第一项
      this.$store.commit("setPlacesInfo", Aes.encrypt(JSON.stringify(obj))); //保存场所信息
      this.$router.push("/home");
    },
    //页面展示形式表格or卡片
    setIsShowPlacesMenu(value) {
      //vuex存储记录选择
      this.$store.commit("setShowPlacesMenu", value);
    },
    //添加场所
    onSubmitAddPlaces() {
      this.loading = true;
      this.$refs["form"].validate(valid => {
        if (valid) {
          addPlacesApi(this.form).then(res => {
            this.loading = false;
            if (res) {
              this.$message.success(res.msg);
              this.dialogFormVisible = false;
              this.initGroup();
            }
          });
        } else {
          return false;
        }
      });
    },
    //添加场所关闭后重置
    closeDialogFormVisible() {
      this.$refs["form"].resetFields();
    },
    //修改场所
    onSubmitEditPlaces() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          editPlacesApi(this.editForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.dialogFormEditVisible = false;
              this.initGroup();
            }
          });
        } else {
          return false;
        }
      });
    },
    //关闭修改场所后重置
    closeDialogFormEditVisible() {
      this.$refs["editForm"].resetFields();
    },
    //删除场所
    onDelFormBtn(obj) {
      this.$confirm("此操作将永久删除该场所, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delPlacesApi({ groupId: obj.groupId }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initGroup();
          }
        });
      });
    },
    //授权场所
  update(obj) {
    var maxRegister=obj.clientCount;
    var expireTime=obj.expireTime;
    var groupId=obj.groupId;
    editRegistsApi({groupId,maxRegister,expireTime}).then(res =>{
      if(res){
        this.$message.success(res.msg);
            this.initGroup();
            this.dialogManage = false;
      }
    })
  },
    //打开续费窗口
    openRecharge(item) {
      this.isAddClient = DateFormat.judgeTime(item.expireTime);
      this.dialogRecharge = true;
      this.rechargeGroupItem = item;
    },
    //支付完成回调
    onSubmitCallBackPay(val) {
      if (val) {
        this.dialogRecharge = false;
        this.initGroup();
      }
    },
    //打开授权窗口
    openManage(item) {
      this.dialogManage = true;
      this.manageForm.groupId=item.groupId;
      this.manageForm.clientCount = item.clientNumber;
      this.manageForm.expireTime = item.expireTime;
    }
  }
};
</script>
<style lang='scss'>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  max-width: 220px;
}
.layout {
  width: 100%;
  height: 100vh;
  // font-size: 13px;
  letter-spacing: 1px;
}
.group_main {
  background: url("../assets/login/login_home.jpg") center no-repeat;
  background-size: cover;
  text-align: center;
  position: relative;
  padding: 0;
}
.group_list_main {
  height: 100%;
  background: rgba($color: #38466f, $alpha: 0.7);
  padding: 20px 100px;
}
.group_search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 40px;
  align-items: center;
  .group_search_left {
    display: flex;
    align-items: center;
    .menuIcon {
      color: #91a3b7;
      font-size: 28px;
      cursor: pointer;
    }
    .group_addBtn {
      margin: 0 30px;
    }
  }
}
.box_card {
  width: 230px;
  height: 300px;
  transition: transform 0.01s;
  -webkit-transition: all 0.01s;
  border-radius: 10px;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  background: #ffffff;
  color: #303133;
  .box_card_body {
    padding: 20px;
    .box_cms_qyb {
      background: linear-gradient(to right, #4b90f3, #025cdf);
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 6px 12px;
      border-radius: 0 10px 0 10px;
      p {
        color: #ffffff;
        font-size: 12px;
      }
    }
    .box_cms_grb {
      background: linear-gradient(to right, #fe9a46, #fd7f47);
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 6px 12px;
      border-radius: 0 10px 0 10px;
      p {
        color: #ffffff;
        font-size: 12px;
      }
    }
    .box_name {
      margin-top: 20px;
      max-width: 200px;
      text-align: center;
      h2 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .group_in:focus,
    .group_in:hover {
      background: #5e72f9;
      color: #ffffff;
    }
    .box_bottom {
      margin-top: 20px;
      opacity: 0;
    }
  }
}

.box_card:hover {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  box-shadow: 0 6px 13px 0 #619aec8c;
  .box_bottom {
    opacity: 1;
  }
}
.box_col {
  margin-bottom: 30px;
}
.tablesGroupTitle {
  display: flex;
  color: #ffffff;
  background: #5e72f9;
  margin: 4px 0;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
}
.tablesGroup {
  display: flex;
  color: #000000;
  background: #d4d7dd;
  margin: 4px 0;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  align-items: center;
  .tables_version {
    height: 50px;
    position: absolute;
    top: 0;
    left: 35px;
  }
}
.tablesGroup:hover {
  background: #ffffff;
}

.groupTypeClass {
  display: flex;
  justify-content: space-around;
  background: #ffffff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  .groupModal {
    position: relative;
    height: 80px;
    width: 35%;
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #efefef;
    cursor: pointer;
    p {
      font-size: 12px;
      line-height: 28px;
    }
    .title {
      font-size: 18px;
    }
    .complete {
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 25px;
      color: #7dca09;
      width: 10%;
    }
  }
  .selectedModal_qyb {
    position: relative;
    height: 80px;
    width: 35%;
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #5e72f9;
    color: #ffffff;
    cursor: pointer;
    p {
      font-size: 12px;
      line-height: 28px;
    }
    .title {
      font-size: 18px;
    }
    .complete {
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 25px;
      color: #7dca09;
      width: 10%;
    }
  }
  .selectedModal_grb {
    position: relative;
    height: 80px;
    width: 35%;
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fd7e47;
    color: #ffffff;
    cursor: pointer;
    p {
      font-size: 12px;
      line-height: 28px;
    }
    .title {
      font-size: 18px;
    }
    .complete {
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 25px;
      color: #7dca09;
      width: 10%;
    }
  }
}
.avatar-uploader-logo {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.avatar-uploader-logo:hover {
  border-color: #5e72f9;
}
.avatar-uploader-logo-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.group_list_main .el-pagination__total{
  color: #fff;
}
</style>