<template>
  <div>
    <!-- 充值续费增加台数 -->
    <div style="padding:10px 0" v-if="!isAddClient">
      <div>
        <span class="recharge_places_title">终端数量&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <el-input-number v-model="rechargeClient" :min="1" :max="500" size="small"></el-input-number>
      </div>
      <div>
        <span class="recharge_places_title">支付方式</span>
        <div>
          <el-tabs
            v-model="activePay"
            tab-position="left"
            type="border-card"
            style="height: 200px;box-shadow:none;"
            @tab-click="openCode"
          >
            <el-tab-pane label="微信" name="wechat">
              <div
                v-if="activePay=='wechat'"
                v-loading="loading"
                style="height:200px;display:flex;justify-content:space-around;align-items:center"
              >
                <div ref="qrcodeImg"></div>
                <div>
                  支付金额：
                  <span style="color: #f60;font-size: 40px;font-weight: bold;">{{showMoney}}</span>元
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="支付宝" name="alipay">
              <div
                v-if="activePay=='alipay'"
                v-loading="loading"
                style="height:200px;display:flex;justify-content:space-around;align-items:center"
              >
                <iframe target="_parent"
                  :srcdoc="alipay_url"
                  frameborder="no"
                  border="0"
                  marginwidth="10"
                  marginheight="10"
                  scrolling="no"
                  width="120"
                  height="120"
                  style="overflow:hidden"
                ></iframe>
                <div>
                  支付金额：
                  <span style="color: #f60;font-size: 40px;font-weight: bold;">{{showMoney}}</span>元
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div>
          <p class="recharge_places_title">注意事项</p>
          <p>1.如若充值失败，请联系**********</p>
          <p>2.提示信息</p>
        </div>
      </div>
    </div>
    <div v-else>该场所已经到期，无法添加设备台数。请前往续费窗口续费</div>
  </div>
</template>
<script>
import {
  alipayRechargeApi,
  wechatRechargeApi,
  getPayRechargeStatusApi
} from "../api/data";
import QRCode from "qrcodejs2";
import DateFormat from "../utils/date";
export default {
  data() {
    return {
      rechargeClient: 1, //充值窗口中的终端数
      alipay_url: "", //支付宝支付地址
      timer: null,
      activePay: "wechat",
      showMoney: "", //显示充值金额
      loading: false, //二维码加载显示
      isAddClient: false, //该场所是否到期 true到期 false 未到期
      payTimer: null //查询支付完成回调的定时器
    };
  },
  props: {
    groupItem: {}
  },
  watch: {
    //监听修改终端数量
    rechargeClient() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (!this.isAddClient) {
          this.openCode();
        }
      }, 2000);
    }
  },
  created() {
    this.isAddClient = DateFormat.judgeTime(this.groupItem.expireTime);
    this.openCode();
  },
  //关闭窗体销毁事件
  destroyed() {
    clearTimeout(this.payTimer);
  },
  methods: {
    //显示支付二维码
    openCode() {
      if (this.activePay == "wechat") {
        this.loading = true;
        this.$nextTick(() => {
          wechatRechargeApi({
            groupId: this.groupItem.groupId,
            payType: "change_group_add_client",
            clientNumber: this.rechargeClient
          }).then(res => {
            var result = JSON.parse(res.data);
            //支付总金额
            this.showMoney = result.price;
            //微信支付二维码
            var wx_pay = JSON.parse(res.msg);
            this.qrcode(wx_pay.code_url);
            //定时查询支付状态
            this.payTimer = setInterval(() => {
              this.handleQueryPayStatus(result.orderFormId);
            }, 1000);
          });
        });
      } else {
        this.loading = true;
        alipayRechargeApi({
          groupId: this.groupItem.groupId,
          payType: "change_group_add_client",
          clientNumber: this.rechargeClient
        }).then(res => {
          var that = this;
          this.$nextTick(() => {
            var result = JSON.parse(res.data);
            //支付总金额
            this.showMoney = result.price;
            //支付宝二维码
            that.alipay_url = res.msg;
            this.loading = false;
            //定时查询支付状态
            this.payTimer = setInterval(() => {
              this.handleQueryPayStatus(result.orderFormId);
            }, 1000);
          }, 2000);
        });
      }
    },
    //查询回调
    handleQueryPayStatus(id) {
      getPayRechargeStatusApi({ orderFormId: id }).then(res => {
        if (res.data.status == "TRADE_SUCCESS") {
          this.$message.success("支付成功");
          clearTimeout(this.payTimer);
           this.$emit('callBackPay',true);
        }
      });
    },
    //微信地址转换为二维码
    qrcode(url) {
      this.loading = false;
      this.$refs.qrcodeImg.innerHTML = "";
      return new QRCode(this.$refs.qrcodeImg, {
        width: 100,
        height: 100,
        text: url,
        colorDark: "#000",
        colorLight: "#fff"
      });
    },
    //套餐点击第几页
    handleRechargeCurrentChange(currentPage) {
      this.rechargePage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
.recharge_places_title {
  font-size: 16px;
  color: #000000;
  line-height: 30px;
}

.recharge_places_view {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.recharge_places_card {
  width: 150px;
  margin: 5px;
  padding: 10px 0px;
  border: 1px solid #d9d9d9;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}
.recharge_places_card_seleted {
  width: 150px;
  margin: 5px;
  padding: 10px 0px;
  border: 1px solid #eabb6a;
  position: relative;
  border-radius: 4px;
  background: #fffcf5;
  cursor: pointer;
}
.recharge_places_discount {
  color: #ffffff;
  background: #009dff;
  background-image: linear-gradient(139deg, #ff715c, #fe2b38);
  position: absolute;
  top: -10px;
  left: 0px;
  border-radius: 6.75px 0 6.75px 0;
  padding: 2px 10px;
  font-size: 12px;
  line-height: 20px;
}
.recharge_places_card_top {
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: 600;
  padding-top: 10px;
  align-items: center;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.recharge_places_card_middle {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  .recharge_places_card_middle_one {
    color: #f60;
    padding-top: 15px;
  }
  .recharge_places_card_middle_two {
    color: #f60;
    font-size: 40px;
    font-weight: bold;
  }
  .recharge_places_card_middle_three {
    color: rgba(255, 102, 0, 0.35);
    margin-left: 4px;
    padding-top: 15px;
    font-size: 14px;
  }
}
.recharge_places_page {
  display: flex;
  justify-content: center;
}
</style>