export default {
    //时间格式化年月日 时分秒
    dateFormat(time) {
        if (!time) {
            return "";
        }
        var t = new Date(time);
        let year = t.getFullYear();
        let month = this.dateIfAddZero(t.getMonth() + 1);
        let day = this.dateIfAddZero(t.getDate());
        let hours = this.dateIfAddZero(t.getHours());
        let minutes = this.dateIfAddZero(t.getMinutes());
        let seconds = this.dateIfAddZero(t.getSeconds());
        return (
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds
        );
    },
    //时间格式化年月日
    dataFormatYear(time) {
        if (!time) {
            return "";
        }
        var t = new Date(time);
        let year = t.getFullYear();
        let month = this.dateIfAddZero(t.getMonth() + 1);
        let day = this.dateIfAddZero(t.getDate());
        return (
            year +
            "-" +
            month +
            "-" +
            day
        );
    },
    dateIfAddZero(time) {
        return time < 10 ? "0" + time : time;
    },
    //判断时间是否过期
    judgeTime(time) {
        var strtime = time.replace("/-/g", "/"); //时间转换
        //时间
        var date1 = new Date(strtime);
        //现在时间
        var date2 = new Date();
        //判断时间是否过期
        return date1 < date2 ? true : false;
    },
    
    //js计算两个时间差多少天几小时几分几秒
    timeDifference(dateEnd){
        var dateBegin = new Date();//获取当前时间
        // console.log("dateEnd==",dateEnd,dateBegin)
        var dateDiff = new Date(dateEnd).getTime() - dateBegin.getTime();//时间差的毫秒数
        // console.log(new Date(dateEnd).getTime(),dateBegin.getTime(),dateDiff)
        var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));//计算出相差天数
        var leave1=dateDiff%(24*3600*1000)    //计算天数后剩余的毫秒数
        var hours=Math.floor(leave1/(3600*1000))//计算出小时数
        //计算相差分钟数
        var leave2=leave1%(3600*1000)    //计算小时数后剩余的毫秒数
        var minutes=Math.floor(leave2/(60*1000))//计算相差分钟数
        //计算相差秒数
        var leave3=leave2%(60*1000)      //计算分钟数后剩余的毫秒数
        var seconds=Math.round(leave3/1000)
        var timeFn = dayDiff+"天"+hours+"小时"+minutes+"分钟"+seconds+"秒";
        return timeFn;
    }

}